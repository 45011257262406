export default {
    monthlyChartOptions: {
      chart: {
        height: 350,
        zoom: {
          enabled: false
        },
        type: 'line',
        toolbar: {
            show: false,
        },
        sparkline: {
            enabled: true
        }
      },
      dataLabels: {
        enabled: true
      },
      plotOptions: {
        bar: {
            columnWidth: "20%"
        }
      },
      stroke: {
          curve: 'smooth',
          width: 2.5
      },
      colors:['#F44336', '#E91E63', '#9C27B0', '#00ff00', '#ff0000', '#333'],
      fill: {
        colors: ['#D8D8D8']
      },
      xaxis: {
          type: "category",
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      },
      yaxis: {
        axisTicks: {
            show: true
        },
        axisBorder: {
           show: true
        },
        opposite: true,
      },
      noData: {
        text: "Loading..."
      }
    },
    monthlyComparisonOptions: {
      chart: {
        height: 350,
        zoom: {
          enabled: false
        },
        type: 'line',
        toolbar: {
            show: false,
        },
        sparkline: {
            enabled: true
        }
      },
      dataLabels: {
        enabled: true
      },
      plotOptions: {
        bar: {
            columnWidth: "20%"
        }
      },
      stroke: {
          curve: 'smooth',
          width: 2.5
      },
      colors:['#F44336', '#E91E63'],
      fill: {
        colors: ['#D8D8D8']
      },
      xaxis: {
          type: "category",
          categories: [],
      },
      yaxis: {
        axisTicks: {
            show: true
        },
        axisBorder: {
           show: true
        },
        opposite: true,
      },
      noData: {
        text: "Loading..."
      }
    },
  }
  