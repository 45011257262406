<template>
  <div class="mtop-100 font-sfns">
    <b-container>
      <b-row>
        <b-col>
          <label>Month</label>
          <b-form-select v-model="month" :options="months"></b-form-select>
        </b-col>
        <b-col></b-col>
      </b-row>
     </b-container>
    <div class="ml-4 mbottom-100" style="width: 90%;">
      <vue-apex-charts v-if="loaded != false" width="100%" type="area" :options="monthlyComparisonOptions" :series="monthlyComparisonSeries"></vue-apex-charts>
    </div>
  </div>
</template>


<script>
import VueApexCharts from 'vue-apexcharts'
import statsOptions from '@/data/statsOptions.js'

export default {
  title: "Monthly Transactions",
  data() {
    return {
      monthlyComparisonOptions: statsOptions.monthlyComparisonOptions,
      loaded: false,
      month: 1,
      months: [
        {
          value: 1,
          text: "January"
        },
        {
          value: 2,
          text: "February"
        },
        {
          value: 3,
          text: "March"
        },
        {
          value: 4,
          text: "April"
        },
        {
          value: 5,
          text: "May"
        },
        {
          value: 6,
          text: "June"
        },
        {
          value: 7,
          text: "July"
        },
        {
          value: 8,
          text: "August"
        },
        {
          value: 9,
          text: "September"
        },
        {
          value: 10,
          text: "October"
        },
        {
          value: 11,
          text: "November"
        },
        {
          value: 12,
          text: "December"
        }
      ]
    }
  },
  components: {
    VueApexCharts
  },
  computed: {
    monthlyComparisonSeries() {
      return this.$store.getters['transaction/monthComparisonSeries']
    },
  },
  watch: {
    month() {
      if (this.month != "") {
        this.fetchMonthlyComparison()
      }
    },
    '$store.state.transaction.monthly_comparison_categories'(newVal) {
      this.monthlyComparisonOptions = { ...this.monthlyComparisonOptions, ...{
        xaxis: {
          type: "category",
          categories: newVal
        }
     }}
    }
  },
  methods: {
    fetchMonthlyComparison() {
      let loader = this.$loading.show({opacity: 0.9})
      this.$store.dispatch("transaction/fetchMonthlyComparison", {meta: {month: this.month, sub_classification: 1}})
      .then(() => {
        this.loaded = true
        loader.hide()
      })
    }
  },
  created() {
    this.fetchMonthlyComparison()
  }

}
</script>

<style lang="scss">
</style>
